import React, { useState, useEffect } from 'react';

import FormSucceed from '~globals/FormSucceed';
import { Helmet } from 'react-helmet';

const FORM_ID = 'b012ae8e-fdea-4826-8787-70b6768e2ea4';

const EmailForm = ({ customFormSucceed, ariaLabelledBy, analyticsLocation }) => {
	const [succeed, setSucceed] = useState(false);
	const formContainerId = `hubspotFormContainer_${FORM_ID}`;

	const initializeHubspotForm = () => {
		if ('hbspt' in window) {
			window.hbspt.forms.create({
				region: 'na1',
				portalId: '46220126',
				target: `#${formContainerId}`,
				formId: FORM_ID,
				css: '',
				onFormSubmit: (_, values) => {
					const email = values.find(field => field.name === 'email');
					if (!email) return;
					setSucceed(true);
				},
			});
		} else {
			setTimeout(initializeHubspotForm, 500);
		}
	};

	useEffect(() => {
		initializeHubspotForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (succeed) {
		return customFormSucceed ?? <FormSucceed />;
	}
	return (
		<>
			<Helmet>
				<script type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
			</Helmet>
			<div
				id={formContainerId}
				className="hubspotFormContainer hubspotFormContainerDarkBg"
				width="100%"
				style={{ paddingTop: 0 }}
				aria-labelledby={ariaLabelledBy}
			></div>
		</>
	);
};

export default EmailForm;
