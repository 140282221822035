import React from 'react';
import classNames from 'classnames';
import { renderRichText } from '~utils/richText';
import { useConsent } from '~contexts/Consent';

import RectangularCTA from '~globals/RectangularCTA';
import EligibilityForm from '~globals/EligibilityForm';
import EmailForm from '~globals/EmailForm';
import CookiebotEmbed from '~globals/Cookiebot/CookiebotEmbed';

const WidgetDefault = ({ eyebrow, headline, body, widgetColor, form, cta, companyLogo }) => {
	const consent = useConsent();

	if (!consent && form === 'email subscription') {
		return null;
	}

	if (form === 'cookiebot') {
		return <CookiebotEmbed />;
	}

	return (
		<div
			className={classNames('my-10 border border-black w-full rounded-2xl p-8', {
				'bg-blue-50': widgetColor === 'blue',
				'bg-emerald-50': widgetColor === 'green',
			})}
		>
			{eyebrow && <div className="type-eyebrow mb-4 opacity-60">{eyebrow}</div>}
			{headline && <h4 className="type-h4 mb-4 font-semibold">{headline}</h4>}
			{body && <div className="mb-6">{renderRichText(body)}</div>}
			{!form && (cta || companyLogo) && (
				<div className="flex items-center justify-between mt-6 flex-col lg:flex-row">
					{cta && (
						<RectangularCTA
							className="w-full lg:w-auto"
							text={cta.label}
							href={cta.href}
							newTab={cta.openInNewTab}
						/>
					)}
					{companyLogo && (
						<img className="mt-8 lg:mt-0 block h-9" src={`${companyLogo.url}?fm=webp`} alt="" />
					)}
				</div>
			)}
			{form === 'get started (countries dropdown)' && (
				<EligibilityForm className="mt-6" isRectangularButton={true} />
			)}
			{form === 'email subscription' && <EmailForm />}
		</div>
	);
};

export default WidgetDefault;
